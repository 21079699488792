import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { firebaseConfig } from '@/utils/constants/firebase';
import { useEffect, useState } from 'react';

const app = getApps()?.length ? getApp() : initializeApp(firebaseConfig);

export const auth = getAuth(app);

export function useFirebaseAuth() {
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);
        setAuth(auth);
    }, []);

    return auth;
}


export const firestore = getFirestore(app);
export const functions = getFunctions(app, 'asia-south1');
